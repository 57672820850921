/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */


// vars
$_datepicker-bg           : $white !default;
$_datepicker-color        : $gray !default;
$_datepicker-color-active : $brand-primary !default;


.ui-widgetui-datepicker { background: $_datepicker-bg; }

.ui-datepicker {
	display: none; padding: .2em .2em 0; width: 17em;

	.ui-datepicker-header { padding: .2em 0; position: relative; }

	.ui-datepicker-prev,
	.ui-datepicker-next {
		height: 1.8em; position: absolute; top: 2px; width: 1.8em;

		span { display: block; left: 50%; margin-left: -8px; margin-top: -8px; position: absolute; top: 50%; }
	}

	.ui-datepicker-prev-hover,
	.ui-datepicker-next-hover { top: 1px; }

	.ui-datepicker-prev { left: 2px; }
	.ui-datepicker-next { right: 2px; }
	.ui-datepicker-prev-hover { left: 1px; }
	.ui-datepicker-next-hover { right: 1px; }

	.ui-datepicker-title {
		line-height: 1.8em; margin: 0 2.3em; text-align: center;

		select { font-size: 1em; margin: 1px 0; }
	}

	select {
		&.ui-datepicker-month,
		&.ui-datepicker-year { width: 45%; }
	}

	table { background: $_datepicker-bg; border-collapse: collapse; font-size: .9em; margin: 0 0 .4em; width: 100%; }
	th { border: 0; font-weight: bold; padding: .7em .3em; text-align: center; }

	td {
		border: 0; padding: 1px;

		span,
		a { color: $_datepicker-color; display: block; padding: .2em; text-align: right; text-decoration: none; }
	}

	.ui-datepicker-buttonpane {
		background-image: none; border-bottom: 0; border-left: 0; border-right: 0; margin: .7em 0 0 0; padding: 0 .2em;

		button {
			cursor: pointer; float: right; margin: .5em .2em .4em; overflow: visible; padding: .2em .6em .3em .6em; width: auto;
			&.ui-datepicker-current { float: left; }
		}
	}

	.ui-icon { background-repeat: no-repeat; display: block; left: .5em; overflow: hidden; text-indent: -99999px; top: .3em; }
}

.ui-datepicker-row-break { clear: both; font-size: 0; width: 100%; }
