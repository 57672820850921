#contact-form {
	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		background: 0 0;
		border: 1px solid $white-3;
		text-transform: uppercase;
		font-size: 18px;
		min-height: 45px;
		height: 45px;
		color: $bg-purple;
	}
	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	select.invalid:not(:focus) + label,
	select:invalid:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		color: $bg-purple;
		font-size: 18px;
		line-height: auto;
		padding: 0.9rem;
		top: 0.5rem;
		text-transform: uppercase;
		font-weight: 100;
	}
	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label {
		color: $bg-purple;
		top: -0.875rem;
	}
	.button.negative {
		border: 2px solid $blue-primary;
		border-radius: 2rem;
		color: $bg-purple;
		padding: 0.3rem 4rem;
		font-weight: 400;
	}
}
#ccontact-body-second{
	h4{
		font-size: 20px;
		font-weight: 900;
		color: $bg-purple;
	}
}
