.wrapper-footer {
	position: relative;
}

.phone,
.email {
	@include txt-font-16($white);
	font-weight: 800;
	letter-spacing: -0.44px;
	line-height: 20px;
}

.copyright {
	border-top: 1px solid $links;
	padding-top: 0.5rem;

	p {
		@include txt-font-12($white, 800);
		font-weight: 500;
		letter-spacing: -0.33px;
		line-height: 14px;
		margin: 0;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding: 0.5rem;
			text-align: center;
		}
	}
}
.one-footer {
	background: $white-4;
}

.footer-logo {
	display: none;
}
.foot-firt-trg {
	display: flex;
	column-gap: 1rem;
	align-items: center;
}
.footer-logo-link {
	height: 6rem;
	width: 14rem;
	width: 113px;
}

.footer-sm {
	.ico {
		text-align: center;
		color: $white;
		margin: 0;
		height: auto;
		&::before {
			padding: 4px;
			font-size: 1.5rem !important;
			color: $color-primary;
			border-radius: 30px;
			background: $light-black;
			margin: 0 0.5rem;
		}
	}

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		color: $brand-accent;
	}
}
.social-icons {
	text-align: left;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 2rem;
}

.f-derechos {
	margin-top: 0;
	padding-left: 1rem;
}

footer {
	.p-social {
		&:hover {
			color: $black;
		}
	}
	.footer-sm:hover {
		.p-social {
			color: $black;
		}
	}
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 467px;
		height: 270px;
		background-size: 146px 90px;
		background-position: 0px 169px;
		background-repeat: repeat-x;
		z-index: -1;
	}
	&:after {
		background-size: 146px 90px;
		background-position: 73px -64px;
		background-repeat: repeat-x;
		bottom: 264px;
		z-index: -1;
	}
}

.footer {
	position: relative;
	background: $white;

	.row {
		justify-content: space-around;
		align-items: center;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
	}

	.footer-sm {
		.foot-line-one {
			padding: 2rem 0;
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 2rem;
			img,
			a {
				display: block;
				width: fit-content;
				font-size: 16px;
				color: $bg-purple;
				font-weight: 500;
			}
			img{
				height: 30px;
				width: auto;
			}
		}
	}
}

.two-footer .foot-line-two, .three-footer .foot-line-three{
	p{
		text-align: center;
		font-size: 16px;
		color: $bg-purple;
		margin: 0;
	}

}
.three-footer{
	background: $blue-primary;
	padding: 0.5rem 0;
}

.contact-f {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		flex-direction: column;
	}
}

.footer-sm-fb {
	&.ico {
		color: $color-secondary;
	}
}

.footer-sm-tw {
	&.ico {
		color: $white;
	}
}

.footer-sm-in {
	&.ico {
		color: $white;
	}
}

.footer-sm-yt {
	&.ico {
		color: #ed1c24;
	}
}

.link-privacidad {
	margin-right: 4rem;
	line-height: 40px;
	letter-spacing: -0.33px;

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		margin-left: auto;
		margin: 0 auto 1rem;
		display: block;
	}

	&:hover {
		line-height: 40px;
	}
	&:last-of-type {
		margin-right: 0;
	}
}
