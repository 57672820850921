/*! by SalsaLab */
// Helpers
@import 'bourbon';
// Framework
@import 'functions';
@import 'base/vars';
@import 'variables';
@import 'theme-vars';

@import 'mixins/clearfix';
@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';
@import 'grid';
@import 'utilities/display';
@import 'utilities/flex';
@import 'base/base';

// Layout
@import 'base/layout/header';
@import 'base/layout/menu';
@import 'base/layout/footer';
@import 'base/patterns/swiper';
// @import 'base/patterns/navdrop';
@import 'base/patterns/lightbox';

// Theme Specific
@import 'layout/theme-default';
@import 'layout/theme-animate';


//Custom Style
@import 'layout/theme-section';
@import 'layout/header-layout';
@import 'layout/theme-footer';
@import 'layout/theme-home';
@import 'layout/theme-contacto';

@import 'layout/theme-somos';
@import 'layout/theme-candidatos';
@import 'layout/theme-empresas';






//libraries

//datepicker
@import 'base/patterns/date-picker';
