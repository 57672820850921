.fancy-sub {
	font-size: 23px;
	color: $color-primary;
}
.sec-difference {
	background: url(#{$_image-path}/bkg/corus-dif.png) no-repeat right center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 3rem 0 1rem;
	.col-lg-5 {
		p {
			font-size: 40px;
			color: $white;
			line-height: 1;
			strong {
				font-weight: 800;
			}
		}
		a {
			background: $bg-purple;
			border-radius: 20px;
			color: $blue-primary;
			padding: 0.6rem 1.5rem;
			text-transform: uppercase;
			display: block;
			width: fit-content;
			margin-top: 2rem;
			font-size: 15px;
		}
	}
}
.sec-tit {
	font-size: 40px;
	color: $bg-purple;
}
.sec-financial {
	padding: 3rem 0 1rem;
	h4 {
		font-size: 30px;
		color: $bg-purple;
		font-weight: 100;
	}
	p {
		font-size: 16px;
		color: $bg-purple;
		strong {
			font-weight: 800;
		}
	}
	.financial-grid {
		margin: 3rem 0 0;
		column-gap: 1rem;
		.col-lg-2 {
			background: $blue-second;
			text-align: right;
			h3 {
				font-weight: 800;
				font-size: 40px;
			}
		}
	}
}
.sec-trader {
	padding: 3rem 0 1rem;
	.sec-tit {
		margin-bottom: 2rem;
	}
	.tradericon {
		width: 75px;
		margin-bottom: 2rem;
	}
	p {
		color: $bg-purple;
	}
}
.sec-platform {
	padding: 3rem 0 1rem;
	.sec-content {
		margin-top: 2rem;
		h4 {
			font-size: 30px;
			color: $bg-purple;
			font-weight: 100;
		}
		p {
			color: $bg-purple;
			b {
				font-weight: 800;
			}
		}
	}
}
.sec-weare {
	padding: 3rem 0 1rem;
	.col {
		background-size: cover !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 480px;
		&.col-w-1 {
			background: url(#{$_image-path}/bkg/we-1.png);
		}
		&.col-w-2 {
			background: url(#{$_image-path}/bkg/we-2.png);
		}
		&.col-w-3 {
			background: url(#{$_image-path}/bkg/we-3.png);
		}
		.space {
			flex: 2;
		}
		p {
			font-size: 16px;
			color: $white;
			flex: 1;
		}
		h4 {
			background: $blue-primary;
			font-size: 25px;
			color: $white;
			text-transform: uppercase;
			padding: 0.2rem;
		}
	}
}
.sec-slogan {
	background: url(#{$_image-path}/bkg/corus-slogan.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 6rem 0;
	h3 {
		font-size: 46px;
	}
	h4 {
		font-size: 36px;
	}
	h3,
	h4 {
		color: $white;
		strong {
			font-weight: 800;
		}
	}
}
.sec-imgrid {
	padding: 3rem 0 1rem;
	h4,
	p {
		color: $bg-purple;
	}
	h4 {
		font-weight: 600;
		font-size: 30px;
	}
}
.sec-partners {
	padding: 3rem 0 1rem;
	.row {
		align-items: center;
		h4,
		p {
			color: $bg-purple;
		}
		h4 {
			font-weight: 600;
			font-size: 30px;
		}
	}
}
#icons.sec-trader {
	.col-lg-4 {
		margin-bottom: 2rem;
	}
	.tradericon {
		margin-bottom: 0rem;
	}
}
.sec-risk{
	background: $white-4;
	padding: 1rem 0 0;
	p{
		color: $bg-purple;
		font-size: 16px;
	}
}
.sec-page-top {
	overflow: hidden;

	p {
		@include txt-font-20($text-gral, normal, $font-size-20);
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation: 2s ease 0s normal forwards 1 fadein;
		animation: 2s ease 0s normal forwards 1 fadein;
		opacity: 1;
		letter-spacing: -0.58px;
		text-align: left;

		strong {
			font-weight: 700;
		}
	}

	.slogan {
		color: $color-primary;
		font-size: 20px;
		margin-top: 0;
		font-weight: 300;
	}

	h2 {
		font-size: 48px;
		text-transform: uppercase;
		color: $color-primary;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		letter-spacing: -1.39px;
		text-align: left;
		font-weight: normal;
		margin-bottom: 1rem;		
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			@include txt-font-40($black);
			margin-top: 3rem !important;
		}
	}
	h3 {
		color: $white;
		font-size: 36px;
		font-weight: 100;
		margin: 1rem 0;
	}

	.button {
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin-bottom: 3rem;
		}
	}

	h4 {
		font-size: $font-size-20;
		color: $color-primary;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
		animation: 1.5s ease 0s normal forwards 1 fadein;
		opacity: 1;
		font-weight: 600;
		letter-spacing: -0.83px;
		margin-top: 1rem;
		text-align: left;
		text-transform: uppercase;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			color: $black;
			margin-bottom: 3rem;
		}
	}
}

.sec-empresas-top,
.sec-aduanas-top {
	h2 {
		&::after {
			background: $brand-accent;
			bottom: 12px;
			content: " ";
			display: block;
			height: 6px;
			left: 0;
			position: absolute;
			right: 0;
			width: 115px;
		}
	}
}
.sec-aduanas-top {
	h4 {
		max-width: 30%;
		font-weight: 600;
		font-size: 20px;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			max-width: 100%;
		}
	}
}

.sec-map-top {
	h2 {
		@include line-subtitle();
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: 3rem auto;
		text-transform: uppercase;
	}
}
.sec-servicios {
	.subtitle-line {
		font-weight: 600;
		font-size: $font-size-45;
	}
}

.sec-ayuda-top,
.sec-servicios {
	h2 {
		@include line-subtitle();
		font-size: $font-size-35;
		font-weight: 500;
		line-height: 2.6rem;
		letter-spacing: 1px;
		margin: 0 auto 3rem;
		text-transform: uppercase;
	}
}

.sec-servicios {
	h2 {
		margin-top: 3rem;
		padding-top: 0;
	}
}

.sec-promosiones {
	padding: 3rem 0 8rem;
	position: relative;
	.sec-title {
		color: $light-black;
		font-weight: normal;
		font-size: 36px;
		display: inline-flex;
		
	}
	.row {
		align-items: center;
	}
	.col-lg-8 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 6rem;
	}
	.subtitle-line {
		font-size: 40px !important;
	}
	.col-lg-4 {
		background-color: $color-primary;
		padding: 0 1.5rem;
		color: $white;
		border-radius: 30px;
		height: 330px;
		h2,
		h3 {
			color: $white;
			padding-top: 1rem;
			padding-bottom: 0;
			&.price {
				margin-bottom: 1rem;
				padding-top: 0;
			}
		}
		p {
			margin-top: 0;
		}
		&:nth-child(2) {
			background-color: $white;
			color: $color-primary;
			min-height: 400px;
			h2,
			h3 {
				color: $color-primary;
			}
			-webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
			-moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
			box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		}
		ul {
			text-align: left;
			padding-left: 0;
			li {
				list-style: none;
				background-image: url(#{$_image-path}/bkg/ofera-check.png);
				background-repeat: no-repeat;
				line-height: 30px;
				padding-left: 46px;
				background-position: center left;
				margin-bottom: 1rem;
				&::before {
					content: "";
				}
			}
		}
		.button.alt {
			border: 3px solid $white;
		}
	}
}
.sec-como-funciona {
	.sec-internet.alt {
		position: relative;
		padding-bottom: 4rem;
		.container-fluid {
			max-width: 100%;
		}
	}
}

.sec-clientes {
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
	.row {
		align-items: center;
	}
	.col-lg {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 6rem;
		color: $white;
		padding: 2rem 0 5rem;
		background: $color-primary url(#{$_image-path}/bkg/ofera-dot-back-dos.png) left repeat;
		h2 {
			color: $white;
		}
		p {
			margin-top: 0;
		}
	}
	.row {
		max-width: 1100px;
		margin-right: auto;
		margin-left: auto;
		margin-top: -10rem;
	}
	.col-lg-3 {
		background: $white url(#{$_image-path}/bkg/client-dots.png) 230px 10px no-repeat;
		text-align: left;
		margin-right: 3rem;
		margin-top: 1rem;
		border-radius: 10px;
		padding: 0 1.6rem 0;
		flex: 0 0 30%;
		max-width: 30%;
		-webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		&:last-child {
			margin-right: 0;
		}

		.header-cli {
			display: flex;
			margin-left: -4rem;
			height: 68px;
			img {
				width: 87px;
				height: 87px;
				position: absolute;
				top: -10px;
			}
			div {
				p {
					margin-bottom: 0;
					padding-left: 6rem;
					&:last-of-type {
						margin-top: 0;
					}
					&:first-of-type {
						margin-top: 1.5rem;
						color: $color-primary;
						font-size: $font-size-20;
					}
				}
			}
		}
	}
}

.sec-ventajas {
	padding: 3rem 0 0;
	.col-lg-6 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.subtitle-line {
	@include line-subtitle();
	color: $color-primary;
	font-size: $font-size-48;
	display: inline-flex;
	letter-spacing: -1.39px;
	margin: auto;
	padding: 1.25rem 0 0.8rem;
}

.bg-primary {
	background: $bg-pink-1;
}

.bg-textura {
	p:first-of-type {
		width: 70%;
		margin-bottom: 3rem;
	}
}

.sec-banner-fullwidth {
	h2 {
		@include line-subtitle();
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: auto;
		text-transform: uppercase;
		padding: 1.25rem 0 0.8rem;
	}

	&.bg-primary {
		background: $color-primary url(#{$_image-path}/bkg/fedi-transp.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			@include txt-font-40($white, 700);
			text-transform: initial;
		}
	}

	&.bg-primary-ad {
		background: $color-primary url(#{$_image-path}/bkg/fedi-adu-blue.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0 3.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			color: $white;
		}
	}
}
.bg-textura {
	padding: 5rem 0;
}

.bg-blue-dark {
	background-color: $brand-primary;
}

.intro-default {
	background: $white-3;
}

.sec-reto-top,
.empresas-servicios-info {
	.quienes-text {
		margin: 2.5rem 0;
	}
	.row {
		justify-content: space-around;
		.on-item {
			max-width: 50%;
			padding: 0;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
			}
			&:first-of-type {
				padding-right: 38px;
			}
			&:last-of-type {
				padding-left: 38px;
			}
		}
		.serv-desc {
			text-align: left;
			.subtitle-line {
				margin-bottom: 0;
				padding-bottom: 0;
				line-height: 0.9;
				text-transform: initial;
				font-size: $font-size-40;
			}
		}
	}
}
.empresas-servicios-info {
	padding: 5rem 0;
	.on-item {
		&:last-of-type {
			img {
				padding-top: 28px;
			}
			.serv-desc {
				p {
					margin-top: 3.8rem;
				}
			}
		}
		&:first-of-type {
			.serv-desc {
				p {
					margin-top: 2rem;
				}
			}
		}
	}
}

.sec-metodologia {
	.container-fluid {
		max-width: 100%;
		.col-lg-6 {
			text-align: left;
			padding: 2rem 2rem 2rem 8rem;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
				width: 100%;
				padding: 2rem;
			}
			.serv-list {
				text-align: left;
				margin: initial;
				padding-left: 1em;
				li {
					margin-bottom: 14px;
					list-style: none;
					font-size: 21px;
					&::before {
						content: "\2022";
						color: $brand-accent;
						font-weight: bold;
						display: inline-block;
						width: 1em;
						margin-left: 1em;
					}
				}
			}
			h3 {
				color: $color-secondary;
				margin-bottom: 1rem;
			}
		}
		.back-img {
			background: url(#{$_image-path}/bkg/fedi-servi-image.jpg) no-repeat center bottom;
			background-size: cover;
		}
	}
}
.sec-como-funciona {
	.sec-clientes {
		.grid-valores {
			display: flex;
			column-gap: 0.8rem;
			margin-top: 4rem;
			align-items: center;
			div {
				flex: 1;
			}
		}
		.big-grid {
			margin-top: 4rem;
			.grid-valores {
				margin-top: 0;
			}
		}
	}
}
.fundadoras {
	.sec-title {
		color: $light-black;
		font-weight: normal;
		display: inline-flex;
		
	}
	.container-fluid {
		max-width: 100%;
	}
	.content-alt.mivision {
		justify-content: center;
		div {
			max-width: 440px;
			h4 {
				font-size: 30px;
				font-weight: normal;
				color: $color-primary;
				margin: 1rem 0 2.2rem;
				
			}
			p {
				font-size: 18px;
			}
		}
	}
	.co-fun-foto {
		background: $light-black;
		margin-top: 4rem;
		img {
			margin-top: 4rem;
		}
	}
}
.sec-internet {
	margin-top: 3rem;
	.int-logos {
		margin: 4rem 0 8rem;
	}
	.col-lg-8 {
		margin: 4rem auto;
	}
	.col-lg-2 {
		img {
			width: 77px;
			height: 77px;
		}
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}
.sec-somos {
	#ventajas {
		background: url(#{$_image-path}/bkg/int-todo-back.png) no-repeat center;
		background-size: cover;
		height: 352px;
		position: relative;
		padding-bottom: 1px;
		.col-lg-6 {
			margin: 4rem auto 0;
			font-size: 20px;
		}
	}
}
.sec-servicio {
	.col-lg-6 {
		margin: 4rem auto;
		h2 {
			padding-bottom: 0;
		}
		h4 {
			font-size: $font-size-25;
			font-weight: 100;
			padding-top: 0;
		}
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.sec-blog {
	main {
		&.content {
			background: $white;
			padding: 0 0 4rem;

			.blog-header {
				padding: 4rem 26rem 8rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				background: $color-primary;
				color: $white;

				position: relative;
				&:before,
				&:after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 25px;
					height: 200px;
					background: radial-gradient(closest-side, $white, $white 63%, $color-primary 65%);
					background-size: 146px 90px;
					background-position: 0px 169px;
					background-repeat: repeat-x;
				}
				&:after {
					background: radial-gradient(closest-side, $color-primary, $color-primary 60%, $white 63%);
					background-size: 146px 90px;
					background-position: 73px -64px;
					background-repeat: repeat-x;
					bottom: -174px;
				}

				.search-wrapper {
					width: 70%;

					.search-input {
						border: 1px solid $white;
						border-radius: 100px;
						background: url(#{$_image-path}/bkg/sky-search.svg);
						background-repeat: no-repeat;
						background-position-x: 97%;
						padding: 0 2rem;
						background-position-y: 6px;
					}
				}

				h2 {
					text-transform: uppercase;
					margin-bottom: 2rem;
				}
			}

			.posts-item-dest {
				display: flex;
				position: absolute;
				top: -5rem;
				max-height: 340px;

				.dest-text {
					flex: 1;
					padding: 2rem;
					a {
						color: $black;
						margin-bottom: 2rem;
						display: block;
						font-size: 28px;
						font-weight: 700;
					}

					p {
						color: $white;
					}
				}

				.dest-im {
					flex: 1;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.content-wrapper {
				max-width: 1100px;
				margin: auto;
				display: flex;
				padding: 18rem 0 4rem;
				position: relative;

				#listing {
					width: 70%;
					display: grid;
					grid-template-columns: 4fr 4fr;
					column-gap: 1rem;

					.list-item {
						border-bottom: 4px solid $color-primary;
						margin-bottom: 2rem;
						padding-bottom: 1rem;

						.p-name {
							margin: 1rem 0 2rem;

							.list-blog-date {
								display: none;
							}

							a {
								color: $black;
								font-size: 20px;
							}
						}

						.tags {
							margin-top: 0.5rem;
							display: block;
						}
					}
				}

				#sidebar {
					width: 30%;
					margin-left: 2rem;

					.search-wrapper {
						display: none;
					}

					.b-categories {
						padding: 1rem;
						margin-bottom: 2rem;

						h4 {
							border-bottom: 1px solid $color-primary;
							padding-bottom: 1rem;
							margin-bottom: 1rem;
							font-size: 20px;
							color: $black;
						}

						.tags {
							display: block;

							a {
								display: block;
								color: $black;
							}
						}
					}

					.recent-post {
						background: $color-primary;
						padding: 1rem;

						h4 {
							color: $white;
							margin-bottom: 2rem;
							font-size: 20px;
						}

						ul {
							padding-left: 0;

							li {
								&::before {
									content: none;
								}

								list-style: none;
								border-top: 1px solid $white;
								padding: 1rem 0;
								display: flex;

								.imagencita {
									flex: 1;

									img {
										width: 124px;
										height: 72px;
									}
								}

								a {
									color: $white;
									font-weight: 100;
									flex: 1;
									margin-right: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog-content-item {
	background: $white;

	#item {
		max-width: 1100px;
		margin: auto;
		display: flex;
		padding: 2rem 0;
		position: relative;

		.h-entry {
			flex: 1;

			.list-blog-header {
				padding-top: 650px;

				img {
					position: absolute;
					width: 100%;
					top: 3rem;
					max-height: 600px;
					object-fit: cover;
				}
			}

			a {
				color: $black;
				display: block;
				margin: 1rem 0;
				font-size: 20px;
			}

			hr {
				border: 2px solid $color-primary;
				width: 10%;
				margin-left: 0;
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			h4 {
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					font-size: 12px;
					color: $light-black;
				}
			}
		}

		#sidebar {
			width: 30%;
			margin-left: 2rem;
			margin-top: 650px;

			.search-wrapper {
				margin-bottom: 2rem;

				.search-input {
					border: 1px solid $black;
					border-radius: 100px;
					background: url(#{$_image-path}/bkg/sky-search.svg);
					background-repeat: no-repeat;
					background-position-x: 97%;
					padding: 0 2rem;
					background-position-y: 6px;
				}
			}

			.b-categories {
				padding: 1rem;
				margin-bottom: 2rem;
				color: $black;

				h4 {
					border-bottom: 1px solid $color-primary;
					padding-bottom: 1rem;
					margin-bottom: 1rem;
					font-size: 20px;
				}

				.tags {
					display: block;

					a {
						display: block;
						color: $black;
					}
				}
			}

			.recent-post {
				background: $color-primary;
				padding: 1rem;

				h4 {
					color: $white;
					margin-bottom: 2rem;
					font-size: 20px;
				}

				ul {
					padding-left: 0;

					li {
						&::before {
							content: none;
						}

						list-style: none;
						border-top: 1px solid $white;
						padding: 1rem 0;
						display: flex;

						.imagencita {
							flex: 1;

							img {
								width: 124px;
								height: 72px;
							}
						}

						a {
							color: $white;
							font-weight: 100;
							flex: 1;
							margin-right: 1rem;
						}
					}
				}
			}
		}
	}
}

#ventajas {
	.col-lg {
		background: $color-primary;
	}
	.row {
		align-items: flex-start;
		max-width: 1280px;
		margin-top: 4rem;
	}
}

section {
	.sec-title {
		font-size: 45px;
		text-transform: uppercase;
		font-weight: 700;
		color: $color-primary;
	}
	&.sec-conexiones {
		.sec-title {
			font-size: 36px;
			text-transform: uppercase;
			font-weight: normal;
			color: $white;
			margin-bottom: 2rem;
			padding-bottom: 10px;
			
		}
		p {
			color: $white;
		}
	}
}
#testimonios {
	background: $bg-testimonios;
	padding: 5rem 0 3rem;
	.carousel-inner {
		min-height: 280px;
		.carousel-caption {
			position: initial;
			padding-top: 60px;
			padding-bottom: 60px;
			h5 {
				font-size: 17px;
				color: $text-gral;
				
			}
			hr {
				width: 80%;
				height: 2px;
				background-color: $color-primary;
				border: 0;
			}
			p {
				font-size: 16px;
				font-style: italic;
				color: $light-black;
				
			}
		}
	}
	.carousel-indicators li {
		box-sizing: content-box;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
		width: 12px;
		height: 12px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		cursor: pointer;
		background-color: $color-primary;
		background-clip: padding-box;
		border-top: none;
		border-bottom: none;
		opacity: 0.5;
		transition: opacity 0.6s ease;
		border-radius: 10px !important;
		&.active {
			opacity: 1;
		}
	}
	ol > li::before {
		content: "";
	}
}
.sec-home {
	.sec-contacto {
		margin-top: 0;
	}
}
