.sec-home-top,
.sec-como-top,
.sec-somos-top,
.sec-contact-main {
	p {
		-webkit-animation-duration: 1.5s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1.5s;
		animation-fill-mode: both;
		-webkit-animation: 1s ease 0s normal forwards 1 fadein;
		animation: 1s ease 0s normal forwards 1 fadein;
		opacity: 1;
		font-weight: 600;
		line-height: 25px;
	}
}

// *****
// Slider
// ***
.slide-caption {
	background-color: $white;
	padding: 1rem 3rem;
	box-shadow: 0px 2px 32px 0px rgba(43, 39, 92, 0.75);
	-webkit-box-shadow: 0px 2px 32px 0px rgba(43, 39, 92, 0.75);
	-moz-box-shadow: 0px 2px 32px 0px rgba(43, 39, 92, 0.75);
	&::before {
		content: "";
		width: 96px;
		height: 84px;
		background: $blue-primary;
		display: block;
		position: absolute;
		top: -40px;
		z-index: -1;
	}
	&::after {
		content: "";
		width: 340px;
		height: 84px;
		background: $white-4;
		display: block;
		position: absolute;
		bottom: -40px;
		right: -40px;
		z-index: -1;
	}
	h3 {
		color: $bg-purple;
		font-size: 20px;
		text-align: left;
		font-weight: 100;
	}
	a {
		border: 2px solid $blue-primary;
		border-radius: 20px;
		color: $bg-purple;
		padding: 0.4rem 1.5rem;
		text-transform: uppercase;
		display: block;
		width: fit-content;
		margin-top: 2rem;
		font-size: 15px;
	}
}
.sec-slider {
	// margin-top: 7rem;
	overflow: hidden;
	position: relative;

	.bgw {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//background-color: rgba(255, 255, 255, 0.4);
		z-index: 5;
	}
	&.sec-carusel {
		.container-fluid {
			max-width: 1248px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	.container-fluid {
		max-width: 100%;
		padding: 0;
		width: 100%;
	}

	.container {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		right: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;

		.home-slider-slide {
			margin-top: auto;
			padding-bottom: 6rem;
			width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				width: 90%;
			}
		}

		.button-slide {
			margin-left: 0;
			min-height: 50px;
			position: absolute;
			right: 0;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				min-height: 150px;
			}
			@media (max-width: 580px) {
				min-height: 150px;
				width: 100%;
			}
		}
	}
}
#home-slider .swiper-container {
	height: 660px;
	max-width: 100%;
}
#home-slider .swiper-pagination-bullet {
    background: $white;
    background-clip: padding-box;
    border: 1px solid $white;
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    line-height: 1;
    margin: 1.1rem 4px;
    width: 8px;
	&.swiper-pagination-bullet-active{
		background: transparent;
	}
}
.swiper-slide {
	background-repeat: no-repeat;
	background-size: cover;

	&:first-of-type {
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}
	}

	&:nth-child(1n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.spoty {
			display: none;
		}
		.lastbtn {
			display: none;
		}
	}

	&:nth-child(2n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.erdbtn {
			display: none;
		}
		.lastbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: inline-flex;
			height: 73px;
			width: 170px;
		}
		.nospoty {
			display: none;
		}

		.content-banner {
			display: block !important;

			.button:last-of-type {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&:nth-child(3n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: none;
		}
	}

	&:nth-child(4n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 85% center;
		}

		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.lastbtn {
			display: none;
		}
		.orthbtn {
			display: inline-flex;
		}

		.spoty {
			display: none;
		}
	}

	&:nth-child(5n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}
		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: none;
		}
	}

	&.center-slde-home {
		.content-banner {
			display: block !important;
			h2 {
				margin-left: 0;
				margin-right: 0;
				text-align: left;
			}
		}
	}
}

.swiper-pagination {
	pointer-events: fill;
}

.sec-home-top {
	@include bg-top-page("muve-home.png");
}
.sec-como-top {
	@include bg-top-page("muve-quienes-bkg.jpg");
	max-height: 460px;
	height: 460px;
	.container-fluid {
		.content {
			height: 460px;
			h2 {
				color: $white;
				font-size: 36px;
				
			}
			h3 {
				color: $color-primary;
				margin: 2.4rem 0 0;
				font-size: 30px;
				
			}
			p {
				color: $white;
				margin-left: 0;
				margin-top: 0;
				font-weight: 100;
				
			}
		}
	}
}
.sec-osomos-top {
	@include bg-top-page("int-nosotros.jpg");
}
.sec-contact-main {
	@include bg-top-page("ofera-contact.jpg");
	.container-fluid {
		background-position: center !important;
	}
}

.custom-col {
	max-width: 1100px;
	flex: 1;
}

// ****************************
// conexiones
// ****************************
.sec-conexiones {
	margin-top: 4.3rem;
	overflow: hidden;
	padding: 0 2.5625rem 0;

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		margin-bottom: 4.7rem;
		text-align: center;
		text-transform: uppercase;
	}
	.us-desc {
		text-align: left;
		position: absolute;
		top: 410px;
		padding: 0 10px;
		.subtitle-line {
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: 0.9;
		}
		p {
			font-size: 21px;
		}
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			position: initial;
		}
	}
	.us-icons {
		margin-top: 120px;
		.d-flex {
			div {
				@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
					margin-top: 2rem;
				}
			}
		}
	}
}

.quienes-text {
	display: flex;
	margin-left: auto;
	margin-right: auto;

	.quien-desc {
		width: 50%;
		padding: 2rem 0;
		flex: 1;
	}
	.quien-aux {
		flex: 1;
	}
	img {
		max-width: 454px;
	}
}

.mivi-reg {
	.mivi-text {
		width: 50%;
		padding: 2rem 0;
	}
	img {
		max-width: 454px;
	}
}

.wrapper-animation {
	justify-content: center;
	margin: 0 auto 3.7rem auto;

	.d-flex {
		justify-content: center;
		position: relative;
		align-items: flex-end;
		justify-content: space-around;
	}

	h5 {
		line-height: 1;
		font-weight: 600;
		font-size: 38px;
		letter-spacing: -0.92px;
		order: 2;
		padding-top: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.mv-and {
	@include animate-elm();
	bottom: 2000px;
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;

	svg {
		height: 11.5625rem !important;
		margin: auto;
		width: 11.5625rem !important;
	}

	&:nth-child(1) {
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding-right: 0;
		}
	}

	&:nth-child(2) {
		-webkit-animation-delay: 0.7s;
		animation-delay: 0.7s;
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin: 2rem auto;
			padding-right: 0;
		}
	}

	&:nth-child(3) {
		-webkit-animation-delay: 1.4s;
		animation-delay: 1.4s;
	}

	&.on-item {
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		bottom: 0;
		position: relative;

		&:nth-child(1) {
			&::after {
				-webkit-animation: 1.3s ease 0s normal forwards 1 fadein;
				animation: 1.3s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}

		&:nth-child(2) {
			&::after {
				-webkit-animation: 1.7s ease 0s normal forwards 1 fadein;
				animation: 1.7s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}
	}
}

// ****************************
// sec-servicios
// ****************************
.sec-servicios {
	overflow: hidden;
	background: $white;

	.col-lg-3 {
		min-height: 360px;
		display: flex;
		align-items: flex-end;
		padding: 1rem;
		&.h-one {
			background: url(#{$_image-path}/bkg/ofera-back-1.jpg) no-repeat;
			background-size: cover;
		}
		&.h-two {
			background: url(#{$_image-path}/bkg/ofera-back-2.jpg) no-repeat;
			background-size: cover;
		}
		&.h-three {
			background: url(#{$_image-path}/bkg/ofera-back-3.jpg) no-repeat;
			background-size: cover;
		}
		&.h-four {
			background: url(#{$_image-path}/bkg/ofera-back-4.jpg) no-repeat;
			background-size: cover;
		}
		p {
			color: $white;
			text-align: left;
			font-size: $font-size-25;
			font-weight: 600;
		}
		a {
			color: $white;
			text-align: right;
			width: 100%;
			display: block;
			font-size: $font-size-20;
			font-weight: 100;
			padding-right: 3rem;
			background: url(#{$_image-path}/bkg/arrow-r.png) no-repeat right center;
		}
	}

	.col-lg-6.back-img {
		background: url(#{$_image-path}/bkg/fedi-servicios.jpg) no-repeat center bottom;
		background-size: cover;
	}

	.container-fluid {
		max-width: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		text-align: center;
		text-transform: uppercase;
	}

	.row {
		padding-top: 1.8rem;
		position: relative;
	}

	h3 {
		@include txt-font-30($section-subtitle, 800);
		background: url(#{$_image-path}/bkg/ondas-title-pink.svg) no-repeat center bottom;
		display: inline-flex;
		letter-spacing: -0.83px;
		line-height: 38px;
		margin-bottom: 0.8rem;
		padding-bottom: 1.28rem;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		@include txt-font-18($text-gral, 500);
		letter-spacing: -0.5px;
		line-height: 21px;
		margin: 0 auto 0.5rem;
		position: relative;
		text-align: center;
		z-index: 5;
	}

	.button {
		margin-top: 1.5rem;
	}
}

// ****************************
// sec-testimonios
// ****************************
.sec-testimonios {
	background: $bg-testimonios;
	height: 456px;
	margin-top: 4.5rem;
	padding-top: 4.375rem;
	position: relative;

	&::before {
		@include circle-lateral();
		left: 0;
		transform: translate(-50%, -50%);
	}

	&::after {
		@include circle-lateral();
		right: 0;
		transform: translate(50%, -50%);
	}

	.swiper-pagination {
		bottom: 90px;
		pointer-events: fill;
	}

	.container-fluid {
		height: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.swiper-container {
		height: 456px;
		pointer-events: none;
	}

	.swiper-pagination-bullet {
		background: $bg-pink-1;
		border: 0;
		height: 0.7rem;
		margin: 0.3rem;
		opacity: 0.8;
		width: 0.7rem;

		&.swiper-pagination-bullet-active  {
			opacity: 1;
		}
	}

	h3 {
		@include txt-font-50($brand-primary, $font-size-50);
		@include line-subtitle();
		display: inline-flex;
		font-weight: 900;
		letter-spacing: -1.39px;
		line-height: 63px;
		margin: 0 auto 2.5rem;
		text-align: center;
		text-transform: uppercase;
		word-break: break-all;
	}

	p {
		@include txt-font-16($text-gral);
		border-bottom: solid 1px $brand-accent;
		font-weight: 500;
		letter-spacing: -0.47px;
		margin-bottom: 1.3rem;
		padding-bottom: 1.5625rem;
		width: 60.5%;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			width: 100%;
		}
	}

	h6 {
		@include txt-font-16($text-gral);
		font-style: italic;
	}
}

.sec-como-funciona {
	.sec-clientes {
		padding: 4rem 0;
		min-height: 12rem;
		background: $bg-testimonios url(#{$_image-path}/bkg/muve-quienes-sec.png) left/no-repeat;
		background-size: 45%;
		min-height: 540px;
		margin: 0;
		p {
			font-size: 24px;
			text-align: left;
			line-height: 1.2;
			margin-top: 3rem;
			
		}
	}

	.sec-internet.alt {
		.sec-title {
			color: $light-black;
			font-weight: normal;
			display: inline-flex;
			margin-bottom: 3rem;
			
		}
		.pro-list {
			margin: 4rem 0;
			min-height: 12rem;
			background: url(#{$_image-path}/bkg/muve-obj.jpg) left/no-repeat;
			background-size: 45%;
			min-height: 540px;
			.content-alt {
				display: flex;
				max-width: 1100px;
				width: 1100px;
				margin: auto;
				.quien-aux,
				.quien-desc {
					flex: 1;
					ul {
						padding-left: 0;
					}
				}
			}
		}
	}
}

.content-alt {
	max-width: 1100px;
	width: 1100px;
	margin: auto;
	&.mivision {
		display: flex;
		column-gap: 2rem;
		align-items: flex-start;
		div {
			flex: 1;
			h4 {
				font-size: 30px;
				text-transform: uppercase;
				color: $light-black;
				
			}
		}
	}
}

.sec-home {
	.sec-conexiones {
		padding: 4rem 0;
		min-height: 12rem;
		background: $light-black url(#{$_image-path}/bkg/muve-quienes.png) left/no-repeat;
		background-size: 45%;
		margin: 0;
	}

	.sec-internet {
		padding: 6rem 0;
		min-height: 12rem;
		background: $light-black;
		margin: 0;
	}

	.sec-page-top {
		padding: 0;
	}

	.sec-promosiones {
		.grid-valores {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 0.8rem;
			margin-top: 4rem;
			h4 {
				text-transform: uppercase;
				font-size: 30px;
				color: $color-primary;
				font-weight: 100;
				margin: 1rem 0 1.5rem;
				
			}
		}
	}
}

.soluciones-reg {
	text-align: center;
	.solu-title {
		width: 673px;
		margin: auto;
	}
	.sec-title {
		font-size: 36px;
		text-transform: uppercase;
		font-weight: 400;
		color: $white;
		
	}
	.soluciones-icons {
		display: flex;
		justify-content: space-between;
		margin-top: 6rem;
		align-items: flex-end;
		p {
			color: $color-primary;
			text-transform: uppercase;
			font-size: 24px;
			
			margin-top: 3rem;
		}
	}
}

.whats {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 1rem 0;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	z-index: 999;
}
.whats img {
	width: 80px;
}
