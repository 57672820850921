.sec-page-top {
	height: 240px;
	background: $blue-primary;
	.container-fluid {
		height: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	h2 {
		font-size: 40px;
		font-weight: 800;
		text-transform: uppercase;
	}
}
.sec-body {
	padding: 2rem 0;
	p {
		color: $bg-purple;
		strong {
			font-weight: 600;
		}
	}
}

#characteritics-body{
	h4 {
		font-weight: 800;
		font-size: 20px;
		color: $bg-purple;
	}
}
#privacy-body, #application-body{
	h4 {
		font-size: 22px;
		color: $bg-purple;
		text-transform: uppercase;
	}
}
#fundamental-body{
	h3{
		font-size: 20px;
		font-weight: 900;
		text-transform: uppercase;
	}
}

.with-table {
	h4 {
		text-transform: uppercase;
		font-size: 20px;
		color: $bg-purple;
	}
	span.alt-row {
		background: $white-4;
		width: 100%;
		display: block;
	}
	.thable-examples {
		p {
			span {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex: 1;
				padding: 0.5rem;
				&:first-of-type {
					flex: 0.5;
				}
			}
		}
	}
	.disclosure {
		p.disclosure-p {
			background: $bg-purple;
			color: $white;
			padding: 1rem;
		}
		a {
			border: 2px solid $blue-primary;
			border-radius: 2rem;
			color: $bg-purple;
			padding: 0.3rem 4rem;
			font-weight: 400;
			text-transform: uppercase;
		}
	}
}

a.blue-link-circle{
	border: 2px solid $blue-primary;
	border-radius: 2rem;
	color: $bg-purple;
	padding: 0.3rem 4rem;
	font-weight: 400;
	text-transform: uppercase;
}
